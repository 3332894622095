import React from "react"

import { useIntl } from "react-intl"

const Status = () => {
  const intl = useIntl()
  return (
    <>
      <div className="bg-success text-center text-white p-1">
        {intl.formatMessage({
          id: "Status.Welcome",
        })}
      </div>
      <div className="bg-danger text-center text-white p-1">
        Buy/sell ended in June 2022. More information is available <a href="https://belgacoin.zendesk.com/hc/en-us/articles/6164511114898" target="_blank">here</a>.
      </div>
    </>
  )
}

export default Status
