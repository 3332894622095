import React from "react"

import { useIntl } from "react-intl"
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"

const FormattedMarkdown = ({ id, values }) => {
  const intl = useIntl()
  return (
    <ReactMarkdown
      children={intl.formatMessage({ id: id }, values)}
      remarkPlugins={[gfm]}
    />
  )
}

export default FormattedMarkdown
