import React from "react"

import { useAuth0 } from "@auth0/auth0-react"

const LoggedIn = ({ children }) => {
  const { isAuthenticated } = useAuth0()
  return <>{isAuthenticated && children}</>
}

export default LoggedIn
