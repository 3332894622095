import React from "react"

import Error from "./error"
import Loading from "./loading"

class ExchangeRatesBody extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  componentDidMount() {
    this.myRef.current.height =
      this.myRef.current.parentNode.offsetWidth / (16 / 9) + 36
  }

  render() {
    return (
      <div className="border">
        <iframe
          ref={this.myRef}
          src="https://app.powerbi.com/view?r=eyJrIjoiODMzODg2YTctOWJiYy00Njg0LTk0NDMtNjg3ODlkOWI5YmI3IiwidCI6IjM5ZmY3ODFmLTgxZjAtNGVlMi04MGNiLThiMWUyNzdiYmMwNyIsImMiOjh9"
          frameBorder="0"
          allowFullScreen={true}
          className="w-100"
          style={{ marginBottom: "-5px" }}
        ></iframe>
      </div>
    )
  }
}

class ExchangeRatesLeft extends React.Component {
  constructor(props) {
    super(props)
    this.getFractionDigits = this.getFractionDigits.bind(this)
    this.getRate = this.getRate.bind(this)
    this.refresh = this.refresh.bind(this)
    this.renderCryptoLine = this.renderCryptoLine.bind(this)
    this.renderFiatLine = this.renderFiatLine.bind(this)
    this.state = {
      isLoaded: false,
      error: null,
      items: [],
      itemsYesterday: [],
    }
  }

  componentDidMount() {
    this.timer = setInterval(this.refresh, 60 * 1000)
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  getFractionDigits(n) {
    if (n < 0.0001) {
      return 8
    } else if (n < 0.01) {
      return 6
    } else if (n < 1) {
      return 4
    } else {
      return 2
    }
  }

  getRate(currencyFrom, currencyTo) {
    var rates = this.state.items
    for (var i = 0; i < rates.length; i++) {
      var rate = rates[i]
      if (rate.currencyFrom == currencyFrom && rate.currencyTo == currencyTo) {
        return rate.value
      }
    }
    return null
  }

  getRateYesterday(currencyFrom, currencyTo) {
    var rates = this.state.itemsYesterday
    for (var i = 0; i < rates.length; i++) {
      var rate = rates[i]
      if (rate.currencyFrom == currencyFrom && rate.currencyTo == currencyTo) {
        return rate.value
      }
    }
    return null
  }

  refresh() {
    {
      const url = new URL("/exchange_rates/latest", process.env.GATSBY_API_URL)
      fetch(url, { method: "GET" })
        .then(response => {
          if (response.ok) {
            response.json().then(json => {
              this.setState({ isLoaded: true, error: null, items: json })
            })
          } else {
            response.text().then(text => {
              this.setState({ isLoaded: true, error: text })
            })
          }
        })
        .catch(error => {
          this.setState({
            isLoaded: true,
            error: "Error: " + error.message + ".",
          })
        })
    }
    {
      const url = new URL("/exchange_rates/1d", process.env.GATSBY_API_URL)
      fetch(url, { method: "GET" })
        .then(response => {
          if (response.ok) {
            response.json().then(json => {
              this.setState({
                isLoaded: true,
                error: null,
                itemsYesterday: json,
              })
            })
          } else {
            response.text().then(text => {
              this.setState({ isLoaded: true, error: text })
            })
          }
        })
        .catch(error => {
          this.setState({
            isLoaded: true,
            error: "Error: " + error.message + ".",
          })
        })
    }
  }

  renderCryptoLine(currency) {
    const rate = this.getRate(currency, "EUR")
    if (rate == null) return
    const fd = this.getFractionDigits(rate)
    const rateYesterday = this.getRateYesterday(currency, "EUR")
    if (rateYesterday == null) return
    const increase = ((rate - rateYesterday) / rateYesterday) * 100
    return (
      <tr>
        <th className="text-center">{currency}</th>
        <td className="text-right">
          {rate.toFixed(fd)}{" "}
          <small style={{ color: increase >= 0 ? "green" : "red" }}>
            {increase >= 0 && "+"}
            {increase.toFixed(2)}%
          </small>
        </td>
      </tr>
    )
  }

  renderFiatLine(currency) {
    const x = this.getRate("EUR", currency)
    if (x == null) return
    const rate = 1 / x
    const fd = 4
    const xYesterday = this.getRateYesterday("EUR", currency)
    if (xYesterday == null) return
    const rateYesterday = 1 / xYesterday
    const increase = ((rate - rateYesterday) / rateYesterday) * 100
    return (
      <tr>
        <th className="text-center">{currency}</th>
        <td className="text-right">
          {rate.toFixed(fd)}{" "}
          <small style={{ color: increase >= 0 ? "green" : "red" }}>
            {increase >= 0 && "+"}
            {increase.toFixed(2)}%
          </small>
        </td>
      </tr>
    )
  }

  render() {
    const { isLoaded, error, items } = this.state
    if (!isLoaded) {
      return <Loading />
    } else if (error) {
      return <Error error={error} />
    } else {
      return (
        <>
          <table className="table table-bordered table-sm table-striped">
            <thead>
              <tr>
                <th style={{ width: "40%" }}></th>
                <th className="text-center">EUR / 24h</th>
              </tr>
            </thead>
            <tbody>
              {this.renderCryptoLine("BTC")}
              {this.renderCryptoLine("ETH")}
              {this.renderCryptoLine("BNB")}
              {this.renderCryptoLine("ADA")}
              {this.renderCryptoLine("ALGO")}
              {this.renderCryptoLine("ATOM")}
              {this.renderCryptoLine("AXS")}
              {this.renderCryptoLine("BAT")}
              {this.renderCryptoLine("BCH")}
              {this.renderCryptoLine("BTG")}
              {this.renderCryptoLine("DOGE")}
              {this.renderCryptoLine("DOT")}
              {this.renderCryptoLine("FIL")}
              {this.renderCryptoLine("LINK")}
              {this.renderCryptoLine("LTC")}
              {this.renderCryptoLine("MATIC")}
              {this.renderCryptoLine("SHIB")}
              {this.renderCryptoLine("SOL")}
              {this.renderCryptoLine("UNI")}
              {this.renderCryptoLine("USDC")}
              {this.renderCryptoLine("USDT")}
              {this.renderCryptoLine("VET")}
              {this.renderCryptoLine("WBTC")}
              {this.renderCryptoLine("XLM")}
              {this.renderCryptoLine("XRP")}
            </tbody>
          </table>
          <table className="table table-bordered table-sm table-striped">
            <thead>
              <tr>
                <th style={{ width: "40%" }}></th>
                <th className="text-center">EUR / 24h</th>
              </tr>
            </thead>
            <tbody>
              {this.renderFiatLine("BGN")}
              {this.renderFiatLine("CHF")}
              {this.renderFiatLine("CZK")}
              {this.renderFiatLine("DKK")}
              {this.renderFiatLine("GBP")}
              {this.renderFiatLine("HRK")}
              {this.renderFiatLine("HUF")}
              {this.renderFiatLine("PLN")}
              {this.renderFiatLine("RON")}
              {this.renderFiatLine("SEK")}
              {this.renderFiatLine("TRY")}
            </tbody>
          </table>
        </>
      )
    }
  }
}

export { ExchangeRatesBody, ExchangeRatesLeft }
