import React from "react"

import Error from "./error"
import Loading from "./loading"

class News extends React.Component {
  constructor(props) {
    super(props)
    this.refresh = this.refresh.bind(this)
    this.state = {
      isLoaded: false,
      error: null,
      items: [],
    }
  }

  componentDidMount() {
    this.timer = setInterval(this.refresh, 15 * 60 * 1000)
    this.refresh()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.language != prevProps.language) {
      this.refresh()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  refresh() {
    const url = new URL("/news/latest", process.env.GATSBY_API_URL)
    const params = { language: this.props.language }
    Object.keys(params).forEach(key =>
      url.searchParams.append(key, params[key])
    )
    fetch(url, { method: "GET" })
      .then(response => {
        if (response.ok) {
          response.json().then(json => {
            this.setState({ isLoaded: true, error: null, items: json })
          })
        } else {
          response.text().then(text => {
            this.setState({ isLoaded: true, error: text })
          })
        }
      })
      .catch(error => {
        this.setState({
          isLoaded: true,
          error: "Error: " + error.message + ".",
        })
      })
  }

  render() {
    const { isLoaded, error, items } = this.state
    if (!isLoaded) {
      return <Loading />
    } else if (error) {
      return <Error error={error} />
    } else {
      return <List items={items} />
    }
  }
}

function List(props) {
  return (
    <ul className="list-group">
      {props.items.map(item => (
        <Item key={item.id} value={item} />
      ))}
    </ul>
  )
}

function Item(props) {
  const value = props.value
  return (
    <a
      href={value.link}
      target="_blank"
      className="list-group-item list-group-item-action"
    >
      {value.title}
    </a>
  )
}

export default News
