import React from "react"

import { Link } from "gatsby"
import PropTypes from "prop-types"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useIntl } from "react-intl"

import belgacoin from "../images/belgacoin.png"
import flag_en from "../images/flag_en.png"
import flag_de from "../images/flag_de.png"
import flag_fr from "../images/flag_fr.png"
import flag_nl from "../images/flag_nl.png"
import flag_ru from "../images/flag_ru.png"
import flag_uk from "../images/flag_uk.png"

const Header = ({ siteTitle, location }) => {
  const intl = useIntl()
  const page = location.pathname.split("/").pop()
  return (
    <header>
      <Row>
        <Col>
          <img
            src={belgacoin}
            alt={intl.formatMessage({ id: "Belgacoin.com Logo" })}
          />
        </Col>
        <Col className="text-right">
          {true && (
            <Link to={"/" + page}>
              <img
                src={flag_en}
                alt={intl.formatMessage({ id: "Flag of the United Kingdom" })}
                className="ml-1 mb-1"
              />
            </Link>
          )}
          {true && (
            <Link to={"/de/" + page}>
              <img
                src={flag_de}
                alt={intl.formatMessage({ id: "Flag of Germany" })}
                className="ml-1 mb-1"
              />
            </Link>
          )}
          {true && (
            <Link to={"/fr/" + page}>
              <img
                src={flag_fr}
                alt={intl.formatMessage({ id: "Flag of France" })}
                className="ml-1 mb-1"
              />
            </Link>
          )}
          {true && (
            <Link to={"/nl/" + page}>
              <img
                src={flag_nl}
                alt={intl.formatMessage({ id: "Flag of the Netherlands" })}
                className="ml-1 mb-1"
              />
            </Link>
          )}
          {false && (
            <Link to={"/ru/" + page}>
              <img
                src={flag_ru}
                alt={intl.formatMessage({ id: "Flag of Russia" })}
                className="ml-1 mb-1"
              />
            </Link>
          )}
          {false && (
            <Link to={"/uk/" + page}>
              <img
                src={flag_uk}
                alt={intl.formatMessage({ id: "Flag of Ukraine" })}
                className="ml-1 mb-1"
              />
            </Link>
          )}
        </Col>
      </Row>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
