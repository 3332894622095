import React from "react"

import PropTypes from "prop-types"
import Alert from "react-bootstrap/Alert"
import FormattedMarkdown from "../components/formatted_markdown"

import { useIntl } from "react-intl"

const Error = ({ error }) => {
  const intl = useIntl()
  return (
    <Alert variant="danger">
      <FormattedMarkdown id={error} />
    </Alert>
  )
}

Error.propTypes = {
  error: PropTypes.string,
}

Error.defaultProps = {
  error: ``,
}

export default Error
