import React from "react"

import PropTypes from "prop-types"

const H1 = ({ pageTitle }) => (
  <h1
    className="text-center text-uppercase text-white mb-3"
    style={{
      background: `linear-gradient(gold, goldenrod)`,
      paddingTop: `12px`,
      paddingBottom: `16px`,
    }}
  >
    {pageTitle}
  </h1>
)

H1.propTypes = {
  pageTitle: PropTypes.string,
}

H1.defaultProps = {
  pageTitle: ``,
}

export default H1
