import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useIntl } from "react-intl"

import Header from "./header"
import Navigation from "./navigation"
import H1 from "./h1"
import { ExchangeRatesLeft } from "./ExchangeRates"
import Fees from "./fees"
import News from "./News"
import Banner from "./banner"
import Space from "../components/space"
import Status from "./status"
import Footer from "./footer"

const Layout = ({ pageTitle, location, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const intl = useIntl()

  return (
    <Container fluid className="my-3">
      <Row className="mb-3">
        <Col>
          <Header
            siteTitle={data.site.siteMetadata.title}
            location={location}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Navigation />
        </Col>
      </Row>
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col md={4} lg={3} xl={2} className="small">
              <H1 pageTitle={pageTitle} />
              <div>
                <h5>{intl.formatMessage({ id: "Exchange Rates" })}</h5>
                <ExchangeRatesLeft />
              </div>
              {false && (
                <div className="d-none d-xl-block">
                  <h5>{intl.formatMessage({ id: "Fees" })}</h5>
                  <Fees />
                </div>
              )}
              <div className="d-none d-xl-block">
                <h5>{intl.formatMessage({ id: "News" })}</h5>
                <News language={intl.locale} />
              </div>
            </Col>
            <Col>
              <Row className="mb-2">
                <Col>
                  <Banner />
                  <Status />
                </Col>
              </Row>
              <Row>
                <Col>
                  <main>{children}</main>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col>
          <Footer />
        </Col>
      </Row>
    </Container>
  )
}

Layout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout
