import React from "react"

import Alert from "react-bootstrap/Alert"
import { useIntl } from "react-intl"

const Loading = () => {
  const intl = useIntl()
  return (
    <Alert variant="secondary">
      {intl.formatMessage({ id: "Loading..." })}
    </Alert>
  )
}

export default Loading
