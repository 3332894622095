import React from "react"

import { useIntl } from "react-intl"

const Footer = () => {
  const intl = useIntl()
  return (
    <footer className="text-center">
      <div>
        <a
          href="https://belgacoin.zendesk.com/hc/en-us/articles/115001335771"
          target="_blank"
          className="mr-2"
        >
          {intl.formatMessage({ id: "Contact" })}
        </a>
        <a
          href="https://belgacoin.zendesk.com/hc/en-us/articles/115000555251"
          target="_blank"
          className="mr-2"
        >
          {intl.formatMessage({ id: "Terms and Conditions" })}
        </a>
        <a
          href="https://belgacoin.zendesk.com/hc/en-us/articles/115000555191"
          target="_blank"
          className="mr-2"
        >
          {intl.formatMessage({ id: "Privacy Policy" })}
        </a>
        <a
          href="https://belgacoin.zendesk.com/hc/en-us/articles/115000555151"
          target="_blank"
          className="mr-2"
        >
          {intl.formatMessage({ id: "AML Policy" })}
        </a>
        <a
          href="https://belgacoin.zendesk.com/hc/en-us/articles/115000563512"
          target="_blank"
          className="mr-2"
        >
          {intl.formatMessage({ id: "Cookie Policy" })}
        </a>
        {intl.formatMessage({ id: "Business/VAT Number BE0560665542" })}
      </div>
      <div>
        © {new Date().getFullYear()} Belgacoin.com. All rights reserved.
      </div>
    </footer>
  )
}

export default Footer
