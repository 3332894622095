import React from "react"

import { useIntl } from "react-intl"

import banner from "../images/banner.jpg"

const Banner = () => {
  const intl = useIntl()
  return (
    <img
      src={banner}
      alt={intl.formatMessage({ id: "Belgacoin.com Banner" })}
      className="img-fluid w-100"
    />
  )
}

export default Banner
