import React from "react"

import { useAuth0 } from "@auth0/auth0-react"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import { useIntl } from "react-intl"

import LoggedIn from "./logged_in"
import NotLoggedIn from "./not_logged_in"

const Navigation = () => {
  const intl = useIntl()
  const { loginWithRedirect, logout } = useAuth0()

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand>
        {intl.formatMessage({ id: "Exchange, Wallet & More" })}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Link to="/" className="nav-link">
            {intl.formatMessage({ id: "Home" })}
          </Link>
          <Link to="/exchange" className="nav-link">
            {intl.formatMessage({ id: "Exchange" })}
          </Link>
          <Link to="/wallet" className="nav-link">
            {intl.formatMessage({ id: "Wallet" })}
          </Link>
          <Link to="/identity" className="nav-link">
            {intl.formatMessage({ id: "Identity" })}
          </Link>
          <NavDropdown title={intl.formatMessage({ id: "More" })}>
            <a
              href="https://support.belgacoin.com/hc/en-us/articles/360027043071"
              target="_blank"
              className="dropdown-item"
            >
              {intl.formatMessage({ id: "Chatbots" })}
            </a>
            <a
              href="https://support.belgacoin.com/hc/en-us/articles/360026954472"
              target="_blank"
              className="dropdown-item"
            >
              {intl.formatMessage({ id: "CryptoLocker Assistance" })}
            </a>
          </NavDropdown>
        </Nav>
        <NotLoggedIn>
          <Form inline>
            <Button
              variant="primary"
              onClick={() =>
                loginWithRedirect({
                  appState: { returnTo: window.location.pathname },
                })
              }
            >
              {intl.formatMessage({ id: "Login / Sign Up" })}
            </Button>
          </Form>
        </NotLoggedIn>
        <LoggedIn>
          <Form inline>
            <Button
              variant="primary"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              {intl.formatMessage({ id: "Logout" })}
            </Button>
          </Form>
        </LoggedIn>
        <Nav>
          <a
            href="https://support.belgacoin.com"
            target="_blank"
            className="nav-link"
          >
            {intl.formatMessage({ id: "Support" })}
          </a>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Navigation
